.form {
    display: grid;
    padding: 20px;
}


.submit {
    background-color: #4CAF50;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 18px;
}

.submit:hover {
    background-color: #45a049;
}
