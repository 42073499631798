.projectWrapper {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
}

.iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}


.testingMenuContent {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  column-gap: 5rem;
}

.testingMenuContent > div {
  display: flex;
  place-items: center;
  cursor: pointer;
}