.header {
  border-bottom: 1px solid #ccc;
  padding: 10px 2rem !important;
  background: var(--info-color);
  color: #fff;
}
.body {
  margin-top: 1rem;
}
.msg {
  display: flex;
  gap: 10px;
}

.msg img {
  width: 30px;
  height: 30px;
}

.dialog_success {
  background: var(--success-color) !important;
}

.dialog_info {
  background: var(--info-color) !important;
}
