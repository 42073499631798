.radioButtonWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  cursor: pointer;
}

.radioButtonInput {
  margin-right: 0.5rem;
  cursor: pointer;
}

.radioButtonLabel {
  font-size: var(--primary-font-size);
  cursor: pointer;
}
