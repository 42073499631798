.expandable {
  display: block;
  width: 100%;
  background-color: #f1f1f1;
  padding: 5px;
}
.title {
  cursor: pointer;
  padding: 10px;
  padding-left: 0;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  transition: 0.4s;
  user-select: none;
  display: flex;
  justify-content: space-between;
}

.title:hover {
  text-decoration: underline;
}

.content {
  overflow: hidden;
  padding-left: 15px;

  /* background-color: #f1f1f1; */
}
