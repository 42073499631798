* {
  margin: 0;
  box-sizing: border-box;
}

*::before,
*::after {
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
}

body {
  font-family: "Roboto", sans-serif;
  font-size: 1.6rem;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  color: #333;
}

button,
input,
textarea,
select {
  font: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

img,
video,
canvas,
svg {
  max-width: 100%;
  height: auto;
}

a {
  color: inherit;
  text-decoration: none;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

button {
  border: none;
  background: none;
  cursor: pointer;
}
