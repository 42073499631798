.multipleChoice {

}

.question {

}

.options {

}

.option {
    display: flex;
    column-gap: 0.5rem;
}
