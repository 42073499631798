:root {
    --primary-color: #4285f4;
    --secondary-color: #db4437;
    --tertiary-color: #f4b400;
    --success-color: #0f9d58;
    --danger-color: #db4437;
    --warning-color: #f4b400;
    --info-color: #4285f4;
    --light-color: #f5f5f5;
    --dark-color: #212121;
  
    --primary-font-size: 14px;
    --secondary-font-size: 12px;
    --primary-font-family: Arial, sans-serif;
    --secondary-font-family: Verdana, sans-serif;
    --base-padding: 10px;
    --base-margin: 20px;
  }
  