.expandableTestingMenu {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  z-index: 9999;
  display: grid;
  grid-template-areas: "title content";
  grid-template-columns: min-content 1fr;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2);
  opacity: 0.5;
  transition: opacity 0.2s ease-in-out;
}

.expandableTestingMenu:hover {
  opacity: 1;
}

.expandableTestingMenuHeader {
  grid-area: title;
  white-space: nowrap;
  font-size: medium;
}

.expandableTestingMenuHeader h1 {
  display: inline-block;
}

.expandableTestingMenuHeader h3 {
  display: inline-block;
  margin-left: 0.5rem;
  color: #999;
}

.expandableTestingMenuContent {
  grid-area: content;
  display: grid;
  justify-content: end;
}

.arrowContainer {
  display: grid;
  place-items: center;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 50px;
  height: 50px;
  background-color: #ccc;
  border-radius: 50%;
  z-index: 9999;
  opacity: 0.5;
  cursor: grab;
}

.arrowContainer:hover {
  opacity: 1;
}
