.project_list {
    list-style: none;
    padding: 0;
  }
  
  .project_list li {
    border-bottom: 1px solid #ccc;
    padding: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .project_list div {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
  
  .project_list button {
    margin-left: 10px;
    padding: 5px 10px;
    border: none;
    cursor: pointer;
  }

  .project_list button p {
    margin: 0;
    font-size: 10px;
  }

  .add p {
    margin: 0;
    font-size: 10px;
  }
  .add {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin: 0 0 10px 0;
    padding: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    cursor: pointer;
    color: green;
    fill: green;
  }