.instructions {
}

.instructions_header {
}

.msgs {
}

.msg {
}

.remove p,
.add p {
  margin: 0;
  font-size: 10px;
}
.add {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: 0 0 10px 0;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  cursor: pointer;
  color: green;
  fill: green;
}

.remove {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 10px 0;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  cursor: pointer;
  color: red;
}
