.dashboard_layout {
  width: 100%;
  height: 100%;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  background-color: #f5f5f5;
  border-bottom: 1px solid #ccc;
}

.title {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
}

.view_toggle {
  display: flex;
}

.all_sessions_btn,
.average_session_btn {
  padding: 0.5rem 1rem;
  background-color: #fff;
  border: 1px solid #ccc;
  font-size: 0.875rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.all_sessions_btn.active,
.average_session_btn.active {
  background-color: #4285f4;
  color: #fff;
}

.all_sessions_btn:hover,
.average_session_btn:hover {
  background-color: #eee;
}

.main_content {
  flex: 1;
  padding: 1rem;
  overflow: auto;
}
