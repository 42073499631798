
.formGroup {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.formGroup label {
    font-weight: bold;
    margin-bottom: 5px;
}

.formGroup input,
.formGroup textarea {
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.type_title {
    font-weight: bold;
    margin-bottom: 5px;
}

.type_container {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.type_container label {
    margin: 0;
    margin-left: 5px;
    font-size: 12px;
    font-weight: normal;
}
