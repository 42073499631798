.likert {
    
}
.likert_question {

}
.likert_options {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0;
    padding: 0;
}
.likert_option {

}