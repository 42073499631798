.manager_select {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
  }
  .manager_select h1 {
    margin-bottom: 1rem;
  }
  .manager_select p {
    margin-bottom: 2rem;
  }
  .manager_select .form_group {
    display: flex;
    flex-direction: column;
  }
  .manager_select .form_group div {
    margin-bottom: 1rem;
  }
  .manager_select .submit_button {
    margin-top: 1rem;
  }
  